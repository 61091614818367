import React from 'react';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';
import _mapKeys from 'lodash/mapKeys';

import AddIcon from '@zola/zola-ui/src/components/SvgIcons/Add';
import GiftTrackerIcon from '@zola/zola-ui/src/components/SvgIcons/GiftTracker';
import ManageRegistryIcon from '@zola/zola-ui/src/components/SvgIcons/ManageRegistry';
import OverviewIcon from '@zola/zola-ui/src/components/SvgIcons/Overview';
import RegistryChecklistIcon from '@zola/zola-ui/src/components/SvgIcons/RegistryChecklist';
import YourPerksIcon from '@zola/zola-ui/src/components/SvgIcons/YourPerks';
import { TagV2, TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';

import NavLink from 'components/navV2/NavLink';
import NavLinkColumn from 'components/navV2/NavLinkColumn';

import './navSecondaryRegistryDropdown.less';

const NavSecondaryRegistryDropdown = ({ node }) => {
  // split registry links from "Other Resources"
  const registryLinksGrouped = _mapKeys(_groupBy(node.children, 'other'), (val, key) => {
    if (key === 'undefined') return 'collection';
    return 'other';
  });
  const otherNode = registryLinksGrouped.other[0];

  const renderWhichIcon = (id) => {
    switch (id) {
      case 'gift-tracker':
        return <GiftTrackerIcon width={24} height={24} className="nav-your-registry__icon" />;
      case 'manage-registry':
        return <ManageRegistryIcon width={24} height={24} className="nav-your-registry__icon" />;
      case 'registry-checklist':
        return <RegistryChecklistIcon width={24} height={24} className="nav-your-registry__icon" />;
      case 'nav-add':
        return <AddIcon width={24} height={24} className="nav-your-registry__icon" />;
      case 'your-perks':
        return <YourPerksIcon width={28} height={28} className="nav-your-registry__icon" />;
      case 'overview':
      default:
        return <OverviewIcon width={24} height={24} className="nav-your-registry__icon" />;
    }
  };

  // TODO: Replace span with new Tag from zola-ui
  const renderChildWithIcon = (child) => (
    <a className="nav-your-registry__link" target="_self" href={child.path}>
      <div className="nav-your-registry__icon-container">{renderWhichIcon(child.icon)}</div>
      <div className="nav-your-registry__copy">
        <div className="nav-your-registry__title">{child.title}</div>
        <div className="nav-your-registry__subtext">{child.subtext}</div>
      </div>{' '}
      {child.newBadge && (
        <TagV2 variant={TagV2Variant.COVE} size={TagV2Size.SMALL}>
          New
        </TagV2>
      )}
    </a>
  );

  const renderOther = (other) => (
    <div className="nav-your-registry__other" key="other">
      <div className="nav-your-registry__other-title">{other.title}</div>
      <div className="nav-your-registry__other-children">
        <NavLinkColumn
          links={other.children.map((child, i) => (
            <NavLink key={i} href={child.path} variant="SecondaryNavOtherResourcesLink">
              {child.title}
            </NavLink>
          ))}
        />
      </div>
    </div>
  );

  return (
    <div className="nav-your-registry">
      <ul className="nav-your-registry__links">
        {registryLinksGrouped.collection.map((child = {}) => (
          <li key={child.title} className="nav-your-registry__item">
            {renderChildWithIcon(child)}
          </li>
        ))}
      </ul>
      {renderOther(otherNode)}
    </div>
  );
};

NavSecondaryRegistryDropdown.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    children: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
        subtext: PropTypes.string,
        path: PropTypes.string,
        icon: PropTypes.string,
        className: PropTypes.string,
        other: PropTypes.bool,
      })
    ),
  }),
};

export default NavSecondaryRegistryDropdown;
